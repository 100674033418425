@import "variables";
.teaser{
    font-size: 9px;
    text-align: left;
    padding-left: 12px;
    @media (max-width: 768px) {
        font-size: 11px;
        padding-left:12px;
    }
}
.chart {
    float: right;
    padding: 20px;
    background: white;
    max-width: 50%;
    img {
        max-width: 300px;
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    @media (max-width: 768px) {
        float: none;
        margin-bottom: 20px;
        max-width: 100%;
    }
}
.chart-info {
    float: left;
    overflow: hidden;
    @media (max-width: 768px) {
        float: none;
        margin: 0 auto;
    }
    .first-sec {
        margin-bottom: 21px;
        overflow: hidden;
    }
    .second-sec {
        margin-bottom: 6px;
        overflow: hidden;
    }
    .info-row {
        overflow: hidden;
        .key {
            color: $picked-bluewood;
            float: left;
            width: 238px;
            font-weight: normal;
            font-size: 18px;
            line-height: 1.7;
            @media (max-width: 768px) {
                width: 73%;
            }
        }
        .value {
            color: $picked-bluewood;
            float: left;
            background: #fdf6e3;
            font-size: 17px;
            width: 82px;
            text-align: right;
            padding: 0 5px;
            @media (max-width: 768px) {
                width: 27%;
            }
        }
    }
}